<template>
  <v-scale-screen :width="this.clientWidth" :height="this.clientHeight">

    <div id="app">
      <router-view />
    </div>
  </v-scale-screen>
</template>
<script>
import ScaleBox from 'vue2-scale-box'
export default {
  components: {
    ScaleBox
  },
  data() {
    return {
      clientWidth: 1920,
      clientHeight: 1080,
    }
  },
  mounted() {
    console.log('%c [ document.documentElement.clientHeight ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', document.documentElement.clientHeight, document.documentElement.clientWidth)
    this.clientWidth = document.documentElement.clientWidth
    this.clientHeight = document.documentElement.clientHeight

    window.onresize = () => {
      this.clientWidth = document.documentElement.clientWidth
      this.clientHeight = document.documentElement.clientHeight
      this.$bus.$emit('resize')
    }

  }
}
</script>

<style >
#app {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  user-select: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-family: 'HarmonyOS_Sans_SC_Regular', sans-serif;
}

@font-face {
  font-family: HarmonyOS_Sans_SC_Regular;
  src: url('../src/common//font/HarmonyOS_Sans_Regular.ttf');
}
</style>
