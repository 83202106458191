export default {
	// api请求地址，小程序必须是https
	// 正式环境
	// baseUrl: 'https://admin.vmaxcloud.com.cn',
	//测试环境
	baseUrl: 'https://www.test.vmaxcloud.com.cn',

	// 本地环境
	// baseUrl: 'http://vmax2.local',

	// baseUrl:process.env.NODE_ENV == 'development' ? 'https://www.test.vmaxcloud.com.cn' : 'https://admin.vmaxcloud.com.cn',

	// 图片域名，小程序必须是https
	imgDomain: 'https://www.test.vmaxcloud.com.cn',
	
	// imgDomain: process.env.NODE_ENV == 'development' ? 'https://www.test.vmaxcloud.com.cn' : 'https://admin.vmaxcloud.com.cn',

	//客服地址
	webSocket: 'wss://admin.vmaxcloud.com.cn/wss',
	// webSocket: 'wss://www.test.vmaxcloud.com.cn/wss',

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒 
	pingTimeOut: 5000,
	// 超时时间 超出时间段将重连系统 单位秒
	// timeout : 15000,

	// H5端域名
	h5Domain: 'https://admin.vmaxcloud.com.cn',

	//分享链接域名
	goodsShareUrl: 'https://admin.vmaxcloud.com.cn/h5',

	// 腾讯地图key
	mpKey: 'ZRDBZ-W3VRB-MJCUS-JBWQ6-OVUBJ-CYFN4',

	//高德地图逆向解析
	mpTransi: '481c2e6b3bdb5fd4c8b0e6950f5058ed',

	//微信redirect_url地址
	redirect_url: 'admin.vmaxcloud.com.cn',

	// api安全
	apiSecurity: "",

	// 公钥
	publicKey: ``,

}