<template>
    <div id="login">
        <div class="login">
            <div class="loginbox">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="5em" class="demo-ruleForm">
                    <el-form-item label="手机号" prop="mobile">
                        <el-input @change="getListByPhone" v-model="ruleForm.mobile" :clearable="true"
                            maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item label="用户名">
                        <el-select @change="selectHandler" v-model="username" placeholder="">
                            <el-option v-for="item in options" :key="item.username" :label="item.username"
                                :value="item.username">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input v-model="ruleForm.password" show-password></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="login-btn" type="warning" @click="loginHandler('ruleForm')">登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { adminLoginAPI, getUserAPI } from '@/common/js/login';
export default {

    data() {
        return {
            ruleForm: {
                mobile: '18156529327',
                password: 'vmax5059127850'
            },
            username: '',
            options: [],
            rules: {
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ]
            },
            showname: '',
            site_id: '',
        };
    },
    created() {
        this.getListByPhone()
        console.log(this.$store.state);
    },
    methods: {
        selectHandler(e) {   // 下拉框 
            console.log(e);
        },
        // 登录
        loginHandler(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // console.log(this.username, this.ruleForm.password, this.site_id);
                    // this.$api.post('/miniapp/Login/shopLogin', {
                    //     username: this.username,
                    //     password: this.ruleForm.password,
                    //     site_id: this.site_id + ''
                    // })
                    //     .then(res => {
                    //         console.log(res);
                    //         if (res.code >= 0) {
                    //             localStorage.setItem('site_name', res.data.site_name)
                    //             localStorage.setItem('username', res.data.username)
                    //             localStorage.setItem('site_id', this.site_id)
                    //             localStorage.setItem('showname', this.showname)
                    //             console.log(this.$store.state);
                    //             localStorage.setItem('token', res.data.token)
                    //             this.$router.push('/desk')
                    //         }
                    //     })
                    adminLoginAPI({
                        username: this.username,
                        password: this.ruleForm.password,
                        site_id: this.site_id + ''
                    })
                        .then(res => {
                            console.log('登录', JSON.parse(res.data));
                            console.log(this.$store);
                            res = JSON.parse(res.data)
                            if (res.code >= 0) {
                                this.$message({
                                    message: res.message,
                                    type: 'success',
                                    center: true
                                });
                                localStorage.setItem('site_name', res.data.site_name)
                                localStorage.setItem('username', res.data.username)
                                localStorage.setItem('site_id', this.site_id)
                                localStorage.setItem('showname', this.showname)
                                localStorage.setItem('token', res.data.token)


                                // console.log(this.$store.state.user);
                                // this.$store.commit('setShowname', this.showname)
                                // this.$store.commit('setSiteid', this.site_id)
                                // this.$store.commit('setUsername', this.username)
                                // this.$store.commit('setToken', res.data.token)
                                // this.$store.commit('setSitename', res.data.site_name)
                                // this.$store.state.user.site_name = this.site_name
                                // this.$store.state.user.site_id = this.site_id
                                // this.$store.state.user.token = this.token
                                // this.$store.state.user.username = this.username
                                this.$router.push('desk')

                            } else {
                                this.$message.error({ message: res.message, center: true });
                            }

                        })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getListByPhone() {   //  输入手机号 获取用户列表
            const phoneNumberRegex = /^1[3456789]\d{9}$/
            console.log(this.ruleForm.mobile.length);
            if (this.ruleForm.mobile.length == 11 && phoneNumberRegex.test(this.ruleForm.mobile)) {
                // this.$api.post('/miniapp/Login/getUserListByMobile', { mobile: this.ruleForm.mobile })
                getUserAPI({ mobile: this.ruleForm.mobile })
                    .then(res => {
                        res = JSON.parse(res.data)
                        console.log(res);
                        if (res.code >= 0 && res.data.length) {
                            this.options = res.data
                            // this.options = [
                            //     {
                            //         username: 'vamax'
                            //     },
                            //     {
                            //         username: 'vaxms1'
                            //     }
                            // ]
                            this.showname = res.data[0].showname
                            this.site_id = res.data[0].site_id
                        }
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#login {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background: url('../../img/img.png') no-repeat;
    background-color: #FCEFE7;
    background-size: 100% 100%;
    background-attachment: fixed;


    .login {
        width: 400px;
        height: 350px;
        border-radius: 20px;
        background-color: white;
        right: 0;
        left: 50%;
        bottom: 0;
        top: 0;
        position: absolute;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }

    .loginbox {
        
        .el-select {
            width: 207px;
            height: 42px;
        }

        /deep/ .el-input__inner {
            width: 207px;
            height: 42px;
        }
        .el-form-item__label {
            width: 3em;
        }

        .login-btn {
            background: linear-gradient(to bottom, #ffb74f, #ff9331);
            // width: 100%;
            width: 207px;
            height: 40px;
            font-size: 14px;
        }
    }



}
</style>