import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import Api from '@/common/js/api.js'
import Util from '@/common/js/utils.js'


import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)
Vue.prototype.$api = Api
Vue.prototype.$util = Util



Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
