import Config from './config';
import axios from 'axios';
import stroe from '@/store'
import Vue from 'vue'
import router from '@/router/index'
const instance = axios.create({
   baseURL: Config.baseUrl,
   timeout: 5000,
});

export default {
   post(url, data) {
      let that = new Vue({ router })
      let token = localStorage.getItem('token')
      // let token = store.state.token
      return instance.post(url, { token, ...data }).then(res => {
         if (JSON.parse(res.data).code == '-10010') {
            that.$message.error('登录已过期，请重新登录！');
            setTimeout(() => {
               that.$router.push({ name: 'login' })
            }, 1000);
         } else {
            return JSON.parse(res.data)
         }
      });
   }
}
