import Vue from 'vue'
import Vuex from 'vuex'



// 引入插件
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)


const store = new Vuex.Store({

  state: {

    site_id: '',
    username: '',
    showname: '',
    token: '',
    site_name: ''

  },
  mutations: {
    setShowname(state, payload) {   // showname
      state.showname = payload
    },
    setSiteid(state, payload) {    // siteid
      state.site_id = payload
    },
    setUsername(state, payload) {    // username
      state.username = payload
    },
    setToken(state, payload) {    // token
      state.token = payload
    },
    setSitename(state, payload) {  //  site_name
      state.site_name = payload
    },
    removeState(state, payload) {
      state[payload] = ''
    }

  },


  /* vuex数据持久化配置 */
  plugins: [createPersistedState()] //添加插件
})


export default store
