import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/loginView/loginView.vue'
import HomeView from '../views/homeView/homeView.vue'
import store from '../store'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/registerView/registerView.vue')
  },
  {
    path: '/desk',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/desk',   // 餐台
        name: 'desk',
        component: () => import('../views/deskView/index.vue')
      },
      {
        path: '/orderfood',   // 点餐
        name: 'orderfood',
        component: () => import('../views/deskView/orderFood/orderFood.vue')
      },
      {
        path: '/order',   // 订单
        name: 'order',
        component: () => import('../views/orderView')
      },
      {
        path: '/bill',  // 账单
        name: 'bill',
        component: () => import('../views/billView')
      },
      {
        path: '/clear', //清洁
        name: 'clear',
        component: () => import('../views/clearView')
      },
      {
        path: '/print', // 打印
        name: 'print',
        component: () => import('../views/printView')
      },
      {
        path: '/statement',  // 报表
        name: 'statement',
        component: () => import('../views/statementView')
      },
      {
        path: '/deskdetail',  // 桌台详情
        name: 'deskdetail',
        component: () => import('../views/deskView/deskDetail/deskDetail.vue')
      },
      {
        path: '/orderdetail',  // 订单详情
        name: 'orderdetail',
        component: () => import('../views/orderView/orderDetail/orderDetail.vue')
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const pathArr = [
    '/desk',
    '/order',
    '/bill',
    '/clear',
    '/print',
    '/statement',
    '/deskdetail',
    '/orderdetail',
    '/orderfood'
  ]
  if (pathArr.indexOf(to.path) !== -1) {
    const token = localStorage.getItem('token')
    // const token = store.state.token
    if (token) {
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})


export default router
