<!-- 顶部信息栏 -->
<template>
    <div class="header">
        <div class="header-left">
            <p>{{ site_name }} &nbsp;&nbsp;|&nbsp;&nbsp;</p>
            <p>商户号:{{ showname }}</p>
        </div>
        <div class="header-right">
            <p><i class="el-icon-user"></i></p>
            <p>{{ username }}</p>
            <el-dropdown trigger="click" @command="editclick">
                <span class="el-dropdown-link">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="edit">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            site_name: '',
            showname: ''
        }
    },
    methods: {
        editclick(command) {
            this.$emit('edit', command)
        }
    },
    created() {
        this.username = localStorage.getItem('username')
        this.site_name = localStorage.getItem('site_name')
        this.showname = localStorage.getItem('showname')  
    }

}
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: space-between;
    color: #666666;
    user-select: none;

    .header-left {
        display: flex;

        p {
            font-size: 26px;
        }

    }

    .header-right {
        display: flex;
        align-items: center;

        p {
            font-size: 20px;
            margin-left: 10px;
        }
    }
}
</style>