<template>
    <div>
        <el-container>
            <el-container>
                <el-row>
                    <el-col :span="13">
                        <el-menu :router=true background-color='#FF8B2B' :default-active="currentPage ?? 'desk'"
                            text-color="#ffffff" class="el-menu-vertical-demo" active-text-color="#FF8B2B"
                            @open="handleOpen" @close="handleClose">
                            <el-menu-item index="desk" class="left-card" @click="click('desk')">
                                <!-- <i class="el-icon-monitor"></i> -->
                                <div class="imgBox">
                                    <img v-if="currentPageIcon == 'desk'" src="../../img/deskAct.png" alt="">
                                    <img v-else src="../../img/desk.png" alt="">
                                </div>
                                <p slot="title">餐台</p>
                            </el-menu-item>
                            <el-menu-item index="order" class="left-card" @click="click('order')">
                                <!-- <i class="el-icon-bell"></i> -->
                                <div class="imgBox">
                                    <img v-if="currentPageIcon == 'order'" src="../../img/dingdanAct.png" alt="">
                                    <img v-else src="../../img/dingdan.png" alt="">
                                </div>
                                <p slot="title"> 订单</p>
                            </el-menu-item>
                            <el-menu-item index="bill" class="left-card" @click="click('bill')">
                                <!-- <i class="el-icon-s-claim"></i> -->
                                <div class="imgBox">
                                    <img v-if="currentPageIcon == 'bill'" src="../../img/zhangdanAct.png" alt="">
                                    <img v-else src="../../img/zhangdan.png" alt="">
                                </div>
                                <p slot="title">账单</p>
                            </el-menu-item>
                            <el-menu-item index="clear" class="left-card" @click="click('clear')">
                                <!-- <i class="el-icon-setting"></i> -->
                                <div class="imgBox">
                                    <img v-if="currentPageIcon == 'clear'" src="../../img/guqingAct.png" alt="">
                                    <img v-else src="../../img/guqing.png" alt="">
                                </div>
                                <p slot="title">沽清</p>
                            </el-menu-item>
                            <el-menu-item index="print" class="left-card" @click="click('print')">
                                <!-- <i class="el-icon-brush"></i> -->
                                <div class="imgBox">
                                    <img v-if="currentPageIcon == 'print'" src="../../img/dayinAct.png" alt="">
                                    <img v-else src="../../img/dayin.png" alt="">
                                </div>
                                <p slot="title">打印</p>
                            </el-menu-item>
                            <el-menu-item index="statement" class="left-card" @click="click('statement')">
                                <!-- <i class="el-icon-pie-chart"></i> -->
                                <div class="imgBox">
                                    <img v-if="currentPageIcon == 'statement'" src="../../img/baobiaoAct.png" alt="">
                                    <img v-else src="../../img/baobiao.png" alt="">
                                </div>
                                <p slot="title">报表</p>
                            </el-menu-item>
                        </el-menu>
                    </el-col>
                </el-row>
                <el-main class="main">
                    <el-header>
                        <nav-header @edit="editHandler"></nav-header>
                    </el-header>
                    <RouterView>
                    </RouterView>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import navHeader from '../../components/nav-header/nav-header.vue'

export default {
    components: {
        navHeader,
    },
    data() {
        return {
            currentPage: localStorage.getItem('path') ?? 'desk',
            currentPageIcon: 'desk'
        }
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        editHandler(command) {
            console.log(command);
            if (command == 'edit') {
                localStorage.removeItem('token')
                localStorage.removeItem('path')
                // this.$store.commit('removeState','site_id')
                this.$router.replace('/')
            }
        },
        click(index) {
            console.log(index);
            this.currentPageIcon = index
            localStorage.setItem('path', index)
        }
    },
    computed: {


    },
    created() {

    },
    mounted() {

    }

}
</script>

<style lang="scss" scoped>


.left-card {
    height: calc(100vh / 7);
    font-size: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-wrap: wrap;

    i {
        font-size: 28px;
        color: #FCEFE7;
        position: absolute;
        top: 10%;
    }
}

.el-menu-vertical-demo {
    width: 200px;
    height: 100vh;
}

.imgBox {
    margin-top: 20px;
    width: 200px;
    display: flex;
    justify-content: center;

    img {
        width: 50px;
        height: 50px;
    }
}

.el-menu-item.is-active {
    background-color: #ffffff !important;
}
.main{
    background-color: #fff;
}
</style>